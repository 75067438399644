import appHeight from '../appHeight';
import { useState } from 'react';
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";
import useFetch from '../useFetch';

// === Pages

import AboutPage from '../Pages/About';
import WritingDetail from '../Pages/WritingDetail';
import PerformanceDetail from '../Pages/PerformanceDetail';
import PerformanceList from '../Pages/PerformanceList';
import WritingList from '../Pages/WritingList';
import Syndicate from '../Pages/Syndicate';

    function Navigation() {

        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/menu?populate=*`)

        const [open, setOpen] = useState(false)
        const [navHeight, setNavHeight] = useState('0px')
        const [delay, setDelay] = useState('0ms')

    
        let nav = null;

        const mouseEnter = () => {
            const bar2 = document.getElementById('bar2');
            const bar3 = document.getElementById('bar3');

            if (open == true) {
                bar2.style.width = "30px";
                bar3.style.width = "10px";
            }
            if (open == false) {
                bar2.style.width = "50px";
                bar3.style.width = "50px";
            }

        }

        const mouseLeave = () => {
            const bar2 = document.getElementById('bar2');
            const bar3 = document.getElementById('bar3');

            if (open == true) {
                bar2.style.width = "50px";
                bar3.style.width = "50px";
            }    
            if (open == false) {
                bar2.style.width = "30px";
                bar3.style.width = "10px";
            }

            
        }

        const menuToggle = () => {
            const menu = document.getElementById('menu-container');
            const panel1 = document.getElementById('panel-one');
            const panel2 = document.getElementById('panel-tw0');
            const panel3 = document.getElementById('panel-three');

            const bar2 = document.getElementById('bar2');
            const bar3 = document.getElementById('bar3');

            if (open == true) {
                console.log(document.documentElement.clientWidth)
                if(document.documentElement.clientWidth < 900 ) {
                    setOpen(false)
                    setNavHeight('0vH')
                    setDelay('00ms')
                    bar2.style.width = "30px";
                    bar3.style.width = "10px"; 
                } else {
                    setOpen(false)
                    setNavHeight('0vH')
                    setDelay('600ms')
                    bar2.style.width = "30px";
                    bar3.style.width = "10px";
                }
            }    

            if (open == false) {
                if(document.documentElement.clientWidth < 900 ) {
                    setOpen(true)
                    setNavHeight('92vH')
                    setDelay('00ms')
                    bar2.style.width = "50px";
                    bar3.style.width = "50px"; 
                } else {
                setOpen(true)
                setNavHeight('90vH')
                setDelay('0ms')
                bar2.style.width = "50px";
                bar3.style.width = "50px";
                }
            }
            
        }

        if (data) {

            nav = data.data.attributes
            console.log(nav)
            return (
                <Router>
                    <div className="nav-wrapper"> 
                        <div className="nav">
                            <div className="menu-icon-title-container">
                                <div 
                                    onMouseEnter={() => mouseEnter()} 
                                    onMouseLeave={() => mouseLeave()} 
                                    onClick={() => menuToggle()}
                                    className="menu-icon">
                                    <div className="menu-bar" id="bar1"></div>
                                    <div className="menu-bar" id="bar2"></div>
                                    <div className="menu-bar" id="bar3"></div>
                                </div>
                                <h1><a href="/">NLG</a></h1>
                            </div>
                            <a href={`mailto:${nav.email}`}>
                                <h2>Contact</h2>
                            </a>
                        </div>
                        <div className="menu-container" style={{height: navHeight, transitionDelay: delay}}>
                            <div className="menu-panel" id="panel-one" style={{height: navHeight}}>
                                <a href="/writings"><h1>Writings</h1></a>
                                <div className="overlay" style={{backgroundImage: `url(${nav.panelOne.data.attributes.url})`}}></div>
                            </div>
                            <div className="menu-panel" id="panel-two" style={{height: navHeight}}>
                                <a href="/performances"><h1>Performances</h1></a>
                                <div className="overlay" style={{backgroundImage: `url(${nav.panelTwo.data.attributes.url})`}}></div>
                            </div>
                            <div className="menu-panel" id="panel-three" style={{height: navHeight}}>
                                <a href="/the-syndicate"><h1>The Syndicate</h1></a>
                                <div className="overlay" style={{backgroundImage: `url(${nav.panelThree.data.attributes.url})`}}></div>
                            </div>
                        </div>
                    </div>
                    <Routes>
                        <Route path="/" element={<AboutPage />} />
                        <Route path="/writings" element={<WritingList />} />
                        <Route path="/writings/:id" element={<WritingDetail />} />
                        <Route path="/performances" element={<PerformanceList />} />
                        <Route path="/performances/:id" element={<PerformanceDetail />} />
                        <Route path="/the-syndicate" element={<Syndicate />} />
                    </Routes>
                </Router>
            );
        }
    }
    
    export default Navigation;