import useFetch from "../useFetch";
import { useParams } from "react-router-dom"
import ReactMarkdown from "react-markdown"
import Texture from "../Tape/NLG-Writings.png"


/* === Components */

    function WritingList() {
        const { id } = useParams()
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/writings?populate=*`)

        let writings = []

        if (data) {

            writings = data.data
            console.log(writings)
            return (
                <div className="writing-list-page">
                    <img className="writing-overlay" src={ Texture } />
                    <h1>Writings</h1>
                    <div className="writing-list-container">
                        {writings.map((writing, index) => 
                        <a href={`/writings/${writing.attributes.slug}`}>
                            { console.log(writing)}
                            <div className="writing-card" key={ index  } style={{backgroundImage: `url(${writing.attributes.headerImage.data.attributes.url})`}}>
                                <div className="writing-card-info">
                                    <h1>{writing.attributes.title}</h1>
                                    <div>
                                        <h3>{writing.attributes.publisher}</h3>
                                        <ReactMarkdown>{writing.attributes.dek}</ReactMarkdown>
                                        <p className="writing-date">{new Date(writing.attributes.publicationDate).toLocaleDateString("en-US")}</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        )}
                    </div>
                    {/* <img className="texture-top-left" src={ Texture} />
                    <img className="texture-bottom-right" src={ Texture} /> */}
                </div>
            );
        }
    }
    
    export default WritingList;