const Gallery = (props) => {

    let images = props.data.galleryMedia.data

    return (
        <div className="gallery-wrapper">  
            {images.map((image) => 
                <img className="gallery-image" src={`${ image.attributes.url }`} />
            )}
        </div>                
    )
    
}
  
export default Gallery;