import useFetch from "../useFetch";
import Tape1 from "../Tape/Tape1.png"
import Tape2 from "../Tape/Tape2.png"
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

    function AboutPage() {
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/about?populate[aboutSection][populate]=*&populate[introImage][populate]=*`)
        
        let about = null;
        let aboutSection = [];

        if (data) {

            about = data.data.attributes
            aboutSection = data.data.attributes.aboutSection

            return (
                <>
                    <div className="page-container intro-image" style={{backgroundColor: `#${about.introImageColor}`}}>
                        <h1>{about.pageTitle}</h1>
                        <div className="overlay" style={{backgroundImage: `url(${about.introImage.data.attributes.url})`}}></div>
                    </div>
                    <div className="intro-image-bar"></div>
                    <div className="page-container biography">
                        <h3>About</h3>
                        <div>
                            <BlocksRenderer content={about.biography} />
                        </div>
                    </div>
                    <div className="about-page-section">
                        <div className="section-image-card">
                            <div className="relative">
                                <img className="section-main-image" src={`${aboutSection[0].mainImage.data.attributes.url}`}/>
                                <img id="tape-1" src={Tape1}/>
                                <img id="tape-2" src={Tape2}/>
                            </div>
                            
                        </div>
                        <div className="section-title-card">
                            <h1>{aboutSection[0].menuTitle}</h1>
                            <img className="section-bg-image" src={`${aboutSection[0].backgroundImage.data.attributes.url}`}/>
                        </div>
                    </div>
                    <div className="about-page-section-reverse">
                        <div className="section-image-card">
                            <div className="relative">
                                <img className="section-main-image" src={`${aboutSection[1].mainImage.data.attributes.url}`}/>
                                <img id="tape-1" src={Tape1}/>
                                <img id="tape-2" src={Tape2}/>
                            </div>
                        </div>
                        <div className="section-title-card">
                            <h1>{aboutSection[1].menuTitle}</h1>
                            <img className="section-bg-image" src={`${aboutSection[1].backgroundImage.data.attributes.url}`}/>
                        </div>
                    </div>
                    <div className="about-page-section">
                        <img className="about-page-section-final" src={`${aboutSection[2].backgroundImage.data.attributes.url}`}/>
                        <div className="section-image-card">
                            <div className="relative">
                                <img className="section-main-image" src={`${aboutSection[2].mainImage.data.attributes.url}`}/>
                                <img id="tape-1" src={Tape1}/>
                                <img id="tape-2" src={Tape2}/>
                            </div>    
                        </div>
                        <div className="section-title-card">
                            <h1>{aboutSection[2].menuTitle}</h1>
                        </div>
                    </div>
                </>
            );
        }
    }
    
    export default AboutPage;