import useFetch from "../useFetch";
import { useParams } from "react-router-dom"
import ReactMarkdown from "react-markdown"
import Texture from "../Tape/NLG-Performance.png"
import { BlocksRenderer } from '@strapi/blocks-react-renderer';


/* === Components */

    function PerformanceList() {
        const { id } = useParams()
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/performances?populate[featureImage][populate]=*`)

        let performances = []

        if (data) {

            performances = data.data
            console.log(performances)
            return (
                <div className="performance-list-page">
                    <h1>Performances</h1>
                    <div className="performance-list-container-border"></div>
                    <div className="performance-list-container">
                        {performances.map((performance, index) => 
                        < a href={`/performances/${performance.attributes.slug}`}>
                            <div className="performance-card" key={ index  } style={{backgroundImage: `url(${performance.attributes.featureImage.data.attributes.url})`}}>
                                <div className="performance-card-info">
                                    <h1>{performance.attributes.performanceTitle}</h1>
                                    <BlocksRenderer content={performance.attributes.projectDescription}/>
                                </div>
                            </div>
                        </a>
                        )}
                    </div>
                    <img className="texture-top-left" src={ Texture} />
                    <img className="texture-bottom-right" src={ Texture} />
                </div>
            );
        }
    }
    
    export default PerformanceList;