import useFetch from "../useFetch";
import ReactMarkdown from "react-markdown";
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

    function Footer() {
        
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/footer`)

        let content = null;

        if (data) {

            content = data.data.attributes.footerText;
            
            return (
                <>
                    <div className="footer">
                        <BlocksRenderer content={content} />
                    </div>
                </>
            );
        }
    }
    
    export default Footer;