const Video = (props) => {
   
    let video = props.data.videoLink
 
    return (
        <div>  
            <iframe className="video" src={video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>                
    )
    
}
  
export default Video;