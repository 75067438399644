import useFetch from "../useFetch";
import { useParams } from "react-router-dom"
import Texture from "../Tape/note paper 1.png"
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

    function WritingDetail() {
        const { id } = useParams()
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/writings?filters[slug][$eq]=${id}&[populate]=*`)

        let writing = null;
        let date = null;
        let sections = null;

        let content = [
            {
              type: 'paragraph',
              children: [{ type: 'text', text: 'A simple paragraph' }],
            },
          ];

        if (data) {
            writing = data.data[0].attributes;
            date = new Date(writing.publicationDate).toLocaleDateString("en-US");
            content = writing.publicationBody
            return (
                <div className="blue-background">
                    <div className="writing-page-container writing">
                        <div className="menu-margin">
                            <a href="/writings"><h1>&#60; Writings</h1></a>
                            <img className="texture-writing-detail" src={ Texture } />
                        </div>
                        <div className="writing-container">
                            <div className="publication-info" style={{backgroundImage: `url(${writing.headerImage.data.attributes.url})`}}>
                                <div className="date-and-publisher">
                                    <h3>{writing.publisher}</h3>
                                    <p>{date}</p>
                                </div>
                                <div className="title-and-dek">
                                    <h1 className="title">{writing.title}</h1>
                                    <h4 className="dek">{writing.dek}</h4>
                                </div>
                            </div>
                            <div className="writing-color-bar"></div>
                            <div className="publication-body">
                                <BlocksRenderer content={content} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    
    export default WritingDetail;