import useFetch from "../useFetch";
import { useParams } from "react-router-dom"

/* === Components */
import Gallery from "../Components/Gallery";
import SingleImage from "../Components/SingleImage";
import Video from "../Components/Video";
import Texture from "../Tape/NLG-Performance-Detail.png"
import ReactMarkdown from "react-markdown"
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

    function PerformanceDetail() {
        const { id } = useParams()
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/performances?filters[slug][$eq]=${id}&populate[performanceMedia][populate]=*&populate[introGallery][populate]=*`)
        
        let performance = null;
        let sections = null;
        let display = [];
        let introGallery = [];

        if (data) {
            console.log(data)
            performance = data.data[0].attributes;
            sections = performance.performanceMedia;
            introGallery = performance.introGallery.galleryMedia.data;

            for (let i = 0; i < sections.length; i ++) {
                if (sections[i].__component === "performance.video") {
                    display.push(<Video data={sections[i]} />)
                } else if (sections[i].__component === "performance.single-image") {
                    display.push(<SingleImage data={sections[i]} />)
                } else if (sections[i].__component === "performance.gallery") {
                    display.push(<Gallery data={sections[i]} />)
                }
            }

            return (
                <div className="performance-page">
                    <img className="performance-detail-texture" src={ Texture } /> 
                    <div className="performance-menu">
                        <h1><a href="/performances">Performances &gt;</a></h1>
                    </div>
                    <div className="performance-container">
                        <div className="gallery-wrapper">  
                            {introGallery.map((image) => 
                                <img className="gallery-image" src={`${ image.attributes.url }`} />
                            )}
                        </div>  
                        <h1>{performance.performanceTitle}</h1>
                        <div className="project-information">
                            <div>
                                <BlocksRenderer content={performance.projectDescription}/>
                            </div>
                            <div className="">
                                <BlocksRenderer content={performance.projectDevelopment}/>
                                <BlocksRenderer content={performance.projectSupport}/>
                            </div>
                        </div>
                        {display.map((section, index) => 
                        <div key={ index }>
                            { section }
                        </div>
                        )}
                    </div>
                </div>
            );
        }
    }
    
    export default PerformanceDetail;