const SingleImage = (props) => {
   
    let image = props.data.image.data.attributes.url

    return (
        <div>  
            <img className="single-image" src={`${ image }`} />
        </div>                
    )
    
}
  
export default SingleImage;