import useFetch from "../useFetch";
import { useParams } from "react-router-dom"
import ReactMarkdown from "react-markdown"
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

/* === Components */
import Gallery from "../Components/Gallery";
import SingleImage from "../Components/SingleImage";
import Video from "../Components/Video";
import Texture from "../Tape/SyndicateTexture.png"

    function Syndicate() {
        const { id } = useParams()
        const { isLoading, error, data } = useFetch(`${process.env.REACT_APP_BACKEND}/api/the-syndicate?populate[introGallery][populate]=*&populate[Collaborator][populate]=*&populate[performances][populate]=*`)
        
        let syndicate = null;
        let introGallery = [];
        let performances = [];
        let collaborators = [];

        if (data) {

            syndicate = data.data.attributes;
            collaborators = data.data.attributes.Collaborator
            introGallery = syndicate.introGallery.galleryMedia.data;
            performances = data.data.attributes.performances.data
            console.log(syndicate)
            // sections = performance.performanceMedia;

            // for (let i = 0; i < sections.length; i ++) {
            //     if (sections[i].__component === "performance.video") {
            //         display.push(<Video data={sections[i]} />)
            //     } else if (sections[i].__component === "performance.single-image") {
            //         display.push(<SingleImage data={sections[i]} />)
            //     } else if (sections[i].__component === "performance.gallery") {
            //         display.push(<Gallery data={sections[i]} />)
            //     }
            // }


            return (
                <div className="syndicate-page">
                    <img className="syndicate-overlay" src={ Texture } />
                    <div className="syndicate-intro">
                        <h1>The Syndicate</h1>
                        <div className="syndicate-description">
                            <BlocksRenderer content={syndicate.description}/>
                        </div>
                    </div>
                    <div className="performance-container">
                        <div className="gallery-wrapper">  
                            {introGallery.map((image) => 
                                <img className="gallery-image" src={`${ image.attributes.url }`} />
                            )}
                        </div>
                        <div className="syndicate-projects">  
                            <h1>Projects</h1>
                            <div className="syndicate-project-list">
                                {performances.map((performance, index) => 
                                < a href={`/performances/${performance.attributes.slug}`}>
                                    <div className="performance-card" key={ index  } style={{backgroundImage: `url(${performance.attributes.featureImage.data.attributes.url})`}}>
                                        <div className="performance-card-info">
                                            <h1>{performance.attributes.performanceTitle}</h1>
                                            <BlocksRenderer content={performance.attributes.projectDescription}/>
                                        </div>
                                    </div>
                                </a>
                                )}
                            </div>
                        </div>
                        <h1>Collaborators</h1>
                        <div className="collaborator-grid">
                            {collaborators.map((collaborator, index) => 
                            <div className="collaborator" key={ index }>
                                <img src={collaborator.headshot.data.attributes.url}/>
                                <h3>{collaborator.name}</h3> 
                                <BlocksRenderer content={collaborator.biography}/>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    }
    
    export default Syndicate;